<template>
  <div class="address_box">
    <div id="header">
      <div @click="$router.go(-1)">
        <van-icon name="arrow-left" size="24" />
      </div>
      <div style="font-size:16px;">地址管理</div>
      <div>
        <van-icon name="plus" size="24" @click="$router.push('/addaddress')" />
      </div>
    </div>
    <van-address-list
      v-model="chosenAddressId"
      :list="list"
      default-tag-text="默认"
      @add="onAdd"
      @edit="onEdit"
      add-button-text="设为收货地址"
      v-if="list.length !== 0"
      style="overflow-y:auto;"
    />
    <div v-else style="color:#999;">
      当前没有地址点击右上角
      <span style="color:var(--fc--);" @click="$router.push('/addaddress')">
        <u>添加地址</u>
      </span>
    </div>
    <div class="address_bottom">
      <div class="btn " @click="onAdd">设为收货地址</div>
      <div class="btn" @click="$router.push('/addaddress')">新增地址</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chosenAddressId: "1",
      list: [],
      id: 0
    };
  },
  created() {
    this.id = window.location.href.split("?id=")[1];
    this.getAddressList();
  },
  methods: {
    onAdd(index) {
      this.$toast("设为收货地址");
      // http操作
      this.$bus.$emit("change-address", this.chosenAddressId);
      this.$router.go(-1);
    },
    onEdit(item, index) {
      this.$router.push("/editaddress?index=" + index);
    },
    async getAddressList() {
      let { data: res } = await this.$http.post("api/getAddressList", {
        id: this.id
      });
      if (res.statusCode !== 0) {
        return this.$toast.fail(res.msg);
      }
      let addressList = res.data.map(i => {
        i.addressDetail = i.address;
        i.is_default == "1" ? (i.isDefault = true) : (i.isDefault = false);
        i.areaCode = this.formatAreaCode(i);
        return i;
      });
      this.list = res.data.map(i => {
        i.address = i.province + i.city + i.county + i.address;
        i.is_default == "1" ? (i.isDefault = true) : (i.isDefault = false);
        i.areaCode = this.formatAreaCode(i);
        return i;
      });
      this.getDetailAddress(res.data);
      window.sessionStorage.setItem("addressList", JSON.stringify(addressList));
    },
    getDetailAddress(addressList) {
      let address = addressList.filter(x => {
        if (x.is_default == "1") {
          return true;
        }
        return false;
      });
      let addressId = window.sessionStorage.getItem("addressId");
      if (!addressId) {
        return (this.chosenAddressId = address[0].id);
      }
      this.chosenAddressId = Number(addressId);
    },
    formatAreaCode(x) {
      let province_code = x.province_code;
      let city_code = x.city_code;
      let county_code = x.county_code;
      x.province_code < 10
        ? (province_code = "0" + x.province_code)
        : (province_code = "" + x.province_code);
      x.city_code < 10
        ? (city_code = "0" + x.city_code)
        : (city_code = "" + x.city_code);
      x.county_code < 10
        ? (county_code = "0" + x.county_code)
        : (county_code = "" + x.county_code);

      let a = province_code + city_code + county_code;
      console.log(a);
      return a;
    }
  }
};
</script>

<style lang="less" scoped>
.address_box {
  padding-top: 6rem;
  background-color: #f9f9f9;
  height: calc(100vh);
  position: relative; 
  .van-address-item {
    // box-shadow: 0 0 5px 5px #eee;
    border-radius: 0 !important;
  }
  .van-address-item:not(:last-child){
    margin-bottom:2px;
  }
  /deep/.van-address-item .van-radio__icon--checked .van-icon{
    background-color:#F6736E;
  }
  .van-address-list {
    padding: 0;
  }
  .van-address-list__bottom {
    display: none;
  }
  .address_bottom{
    position: absolute;
    bottom: 30px;
    left:0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .btn{
      width: 8.75rem;
      height: 2rem;
      color: #fff;
      
      background-color:var(--fc--);
      border-radius: 2rem;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>